@import "../../../baseTheme/settings";

.choose-your-craft-slider {
    margin-bottom: 72px;

    .section-title {
        text-align:center;
        margin-bottom: 40px;

        @include media-breakpoint-down(md) {
            font-size: 30px;
            line-height: 32px;
            margin-bottom: 32px;
        }
    }

    .swiper-slide {
        @include media-breakpoint-up(xxl) {
            max-width: 100%;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 305px;
            width: 100%;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        background: #fff;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-top: 10px;

        &::after {
            display: none;
        }

        &.swiper-button-disabled {
            opacity: 0;
        }
    }

    .swiper-wrapper {
        height: auto;
    }
}

.latets-projects-slider {
    margin-bottom: 72px;

    .section-title {
        text-align:left;
        margin-bottom: 32px;

        @include media-breakpoint-down(md) {
            font-size: 30px;
            line-height: 32px;
            margin-bottom: 32px;
        }
    }

    .swiper-slide {
        @include media-breakpoint-down(md) {
            max-width: 60%;
        }

        @include media-breakpoint-down(xxl) {
            max-width: 305px;
            width: 100%;
        }

        @include media-breakpoint-up(xxl) {
            max-width: 100%;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        background: #fff;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-top: -30px;

        &::after {
            display: none;
        }

        &.swiper-button-disabled {
            opacity: 0;
        }
    }


    .swiper-wrapper {
        height: auto;
    }

    .quick-shop {
        display: none;
    }
}

.shop-by-category {
    margin-bottom: 38px;

    .section-title {
        text-align: center;
        margin-bottom: 40px;

        @include media-breakpoint-down(md) {
            font-size: 30px;
            line-height: 32px;
            margin-bottom: 32px;
        }
    }
}

.backgroundContainer {
    padding: 72px 0;
    margin-bottom: 72px;
}

.section-title-container {
    padding-top: 42px;
    margin-bottom: 40px;

    @include media-breakpoint-down(md) {
        padding-top: 32px;
        font-size: 30px;
        line-height: 32px;
        margin-bottom: 32px;
    }
}

.single-post-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }
}
